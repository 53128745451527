import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {Toolbar, Typography, IconButton, Menu, MenuItem, List, Avatar, Dialog, 
        DialogActions, DialogContent, DialogContentText, DialogTitle, Button, ListItemIcon, ListItemText
} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person'

import MenuIcon from '@material-ui/icons/Menu';

import { logout } from '../../services/users/actions';

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    background: '#d8efd3'
  },
  flex: {
    flex: 1,
  },
});

class _AppBar extends Component {
  state = {
    anchorEl: null,
    dialogOpen: false,
  };

  handleLogout = () => {
    this.handleMenuClose();
    this.props.logout();
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleToggleDialog = () => {
    this.setState(prevState => ({
        dialogOpen: !prevState.dialogOpen,
      })
    )
  }
  
  render() {
    const { classes, user } = this.props;
    const { anchorEl } = this.state;
    const open = !!anchorEl;

    return (
      <AppBar  position="absolute" className={[classes.appBar, 'appBar'].join(' ')}>
        <Toolbar>
          <img src="kis_logo.png" alt="logo"/>
          <Typography variant="h6" color="inherit" className={classes.flex} noWrap>
          
          </Typography>
          <div>
            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
              src=""
            >
              <Avatar alt="Remy Sharp" src={user.profile} />
            </IconButton>
            <Menu
              id="menu-appbar"
              onClose={this.handleMenuClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
            >
              <MenuItem onClick={this.handleMenuClose} component={Link} to="/profile">
              <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Profil" />
              </MenuItem>
              
              <MenuItem onClick={() => {
                this.handleMenuClose();
                this.handleToggleDialog();
              }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Odjava" />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleToggleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEnforceFocus
        >
          <DialogTitle id="alert-dialog-title">Ali ste prepričani, da se želite odjaviti?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sedaj se boste odjavili iz aplikacije eZahtevek.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleToggleDialog} color="primary">
              Prekliči
            </Button>
            <Button onClick={this.handleLogout} color="primary" autoFocus>
              Odjava
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    );
  }
}

_AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.data,
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { logout }),
)(_AppBar);