import axios from 'axios';
import { logout } from './users/actions';
import reduxStore from './store';

const instance = axios.create({
  baseURL: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8080/api' : 'https://kisapi.ezahtevek.eu/api' ,
});

const {dispatch} = reduxStore; // direct access to redux store.

instance.interceptors.response.use(null, (error) => {
  if(error && error.response){
    if (error.response.status === 401) {
      dispatch(logout());
    }else if(error.response.status === 503){
      //props.history.push('/login') //we will redirect user into 503 page   
    }
  }

  if (!error.status) {
    // network error
    return Promise.reject(error);
  }
  
  

  return Promise.reject(error);
})

export default instance;